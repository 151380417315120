:root {
    --main-color: #F6635C;
    /* --main-color: #5C5470; */
    --sub-color: #003049;
    --light: #fff;
    --dark: #000;
}

a {
    color: var(--dark);
    text-decoration: none;
}

header {
    background-color: var(--main-color) !important;
}

a:hover {
    color: var(--dark);
}

.btn-style {}

.text-light {
    color: var(--light) !important;
}

.text-light:hover {
    color: var(--light) !important;
}

.text-main {
    color: var(--main-color) !important;
}

.bg-main {
    background-color: var(--main-color) !important;
}

.border-main {
    border-bottom: 2px solid var(--main-color) !important;
}

.hover-main {
    box-shadow: 0px 0px 5px 5px var(--main-color) !important;
}

.main-shadow:hover {
    box-shadow: 0px 0px 1px 1px var(--main-color) !important;
}

.text-sub {
    color: var(--sub-color) !important;
}

.bg-sub {
    background-color: var(--sub-color) !important;
}

.border-sub {
    border-bottom: 2px solid var(--sub-color) !important;
}

.hover-sub {
    box-shadow: 0px 0px 5px 5px var(--sub-color) !important;
}

.sub-shadow:hover {
    box-shadow: 0px 0px 1px 1px var(--sub-color) !important;
}

.left-navbar-links {
    position: relative;
    height: 100%;
}

.left-navbar-links li.logout-link {
    position: absolute;
    bottom: 0;
}